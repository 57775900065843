.new-booking-leg-header {
  border-bottom: solid 1px #e5e5e5;
  height: 55px;

  &.form-closed {
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgb(124 133 152 / 15%);

    &.error {
      background-color: #dd5147;
    }
  }
}
