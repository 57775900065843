.square-20px {
  width: 20px !important;
  height: 20px !important;
}

.w-100p {
  width: 100%;
}

.w-57 {
  width: 57px;
}
.w-60 {
  width: 60px;
}
.w-85 {
  width: 85px;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.align-center {
  align-items: center;
}

.align-flex-end {
  align-items: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.content-between {
  align-content: space-between;
}

.block {
  display: block;
}

.ml-10 {
  margin-left: 10px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-31 {
  margin-left: 31px;
}

.ml-48 {
  margin-left: 48px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-8 {
  margin-bottom: 8px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-22 {
  padding-bottom: 22px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-8 {
  padding-right: 8px;
}

.pl-16 {
  padding-left: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.h-55 {
  height: 55px;
}
