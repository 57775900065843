.btn {
  height: 26px;
  border-radius: 3px;
  background-color: #2f7ae2;
  padding: 0 12px;
  box-shadow: none;
  margin-left: 10px;

  .btn-label {
    font-family: Arial, serif;
    font-size: 12px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &:first-child {
    margin-left: 0;
  }

  &.btn-danger {
    background-color: #f50057;
  }
}
