@mixin stepVertical() {
  .cat-task {
    // Default
    &.custom-field {
      grid-column: span 3;
    }

    &.field-address_id {
      grid-column: span 12;
    }

    &.field-address {
      grid-column: span 6;
    }

    &.field-address2 {
      grid-column: span 6;
    }

    &.field-postal_code {
      grid-column: span 2;
    }

    &.field-state {
      grid-column: span 2;
    }

    &.field-country {
      grid-column: span 2;
    }

    &.field-contact_name {
      grid-column: span 3;
    }

    &.field-contact_company {
      grid-column: span 3;
    }

    &.field-contact_phone {
      grid-column: span 3;
    }

    &.field-contact_email {
      grid-column: span 3;
    }

    &.field-timezone {
      grid-column: span 6;
    }

    &.field-from_time {
      grid-column: span 12;
    }

    &.field-to_time {
      grid-column: span 3;
    }
  }
}

@mixin stepNormal() {
  .cat-task {
    // Default
    &.custom-field {
      grid-column: span 4;
    }

    &.field-address_id {
      grid-column: span 12;
    }

    &.field-address {
      grid-column: span 12;
    }

    &.field-address2 {
      grid-column: span 12;
    }

    &.field-postal_code {
      grid-column: span 4;
    }

    &.field-state {
      grid-column: span 4;
    }

    &.field-country {
      grid-column: span 4;
    }

    &.field-contact_name {
      grid-column: span 6;
    }

    &.field-contact_company {
      grid-column: span 6;
    }

    &.field-contact_phone {
      grid-column: span 6;
    }

    &.field-contact_email {
      grid-column: span 6;
    }

    &.field-timezone {
      grid-column: span 12;
    }

    &.field-from_time {
      grid-column: span 12;
    }

    &.field-from_time_time {
      grid-column: span 6;
    }

    &.field-period_of_time {
      grid-column: span 6;
    }

    &.field-to_time {
      grid-column: span 6;
    }

    &.field-to_time_time {
      grid-column: span 6;
    }

    &.field-display_time_conflict_warning {
      grid-column: span 12;
    }
  }
}

@mixin stepMobile() {
  .cat-task {
    // Default
    &.custom-field {
      grid-column: span 6;
    }

    &.field-address_id {
      grid-column: span 12;
    }

    &.field-address {
      grid-column: span 12;
    }

    &.field-address2 {
      grid-column: span 12;
    }

    &.field-postal_code {
      grid-column: span 6;
    }

    &.field-state {
      grid-column: span 6;
    }

    &.field-country {
      grid-column: span 12;
    }

    &.field-contact_name {
      grid-column: span 12;
    }

    &.field-contact_company {
      grid-column: span 12;
    }

    &.field-contact_phone {
      grid-column: span 12;
    }

    &.field-contact_email {
      grid-column: span 12;
    }

    &.field-timezone {
      grid-column: span 12;
    }

    &.field-from_time {
      grid-column: span 12;
    }

    &.field-from_time_time {
      grid-column: span 12;
    }

    &.field-period_of_time {
      grid-column: span 12;
    }

    &.field-to_time {
      grid-column: span 12;
    }

    &.field-to_time_time {
      grid-column: span 12;
    }
  }
}
