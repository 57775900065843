.custom-field {
  &.field-iso_type {
    .custom-field-iso-type-wrapper {
      display: grid;
      grid-template-columns: repeat(12, 1fr);

      .custom-field-iso-type-autocomplete {
        grid-column: span 12;

        @media only screen and (max-width: 600px) {
          grid-column: span 12;
        }

        .custom-field-iso-type-adornment {
          svg {
            margin-left: 8px;
            color: #858f96;
          }
        }

        input {
          padding-bottom: 3px;
        }

        .MuiInput-root input {
          padding-left: 0 !important;
        }
      }

      .custom-field-iso-type-placeholder-section {
        grid-column: span 6;

        @media only screen and (max-width: 600px) {
          grid-column: span 0;
        }
      }
    }
  }
}

.custom-field-iso-type-select-option-item {
  font-size: 13px !important;
  width: 100%;
}
