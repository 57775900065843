.order-template-select-block {
  .order-template-select {
    min-width: 291px;

    @media only screen and (max-width: 600px) {
      min-width: unset;
      width: 100%;
      margin-right: 16px;
    }
  }
}
