@import '../../../common/style/index.scss';

.new-booking {
  max-width: 960px;
  display: block;
  margin: 0 auto 68px;

  &.show-map {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    max-width: unset;
    height: calc(100vh - 110px);

    .main-form {
      grid-column: span 8;
      margin-right: 20px;
      margin-left: 20px;
      overflow: auto;
      position: relative;
    }

    .map-section {
      grid-column: span 4;
    }
  }

  .sender-and-order-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;

    @media only screen and (max-width: 600px) {
      display: block;
    }
  }

  .form-body-section {
    //margin: 8px 0 0;
    padding: 5px 16px 10px 16px;
    border-radius: 5px;
    border: solid 1px #e5e5e5;
    background-color: #ffffff;
    box-shadow: 0 5px 15px 0 rgba(124, 133, 152, 0.15);

    @media only screen and (max-width: 600px) {
      padding: 5px 5px 10px 5px;
    }
  }

  .section-label {
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
  }

  .MuiInput-root,
  .manage-MuiInput-root {
    border-radius: 5px;
    border: solid 1px #d4d4d4;
    height: 40px;

    &:before {
      content: none;
    }

    &:after {
      content: none;
    }

    &.Mui-error,
    &.manage-Mui-error {
      border: solid 1px rgba(160, 0, 19, 1);
      color: rgba(160, 0, 19, 1);
    }

    .MuiSelect-root,
    .manage-MuiSelect-root,
    input,
    textarea {
      padding-left: 10px !important;
    }

    input,
    textarea {
      &::placeholder {
        font-style: italic;
        opacity: 0.3;
      }
    }
  }

  .MuiFormLabel-root,
  .manage-MuiFormLabel-root {
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #858f96;
    white-space: nowrap;
  }

  .MuiInputBase-input,
  .manage-MuiInputBase-input {
    padding: 0;
    font-size: 13px;
    font-weight: 500;
  }

  .MuiSelect-select:focus,
  .manage-MuiSelect-select:focus {
    background-color: inherit;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  sup {
    vertical-align: bottom;
    position: relative;
    top: -0.4em;
  }
}
