@import 'containerFieldsHelpers';

.new-booking-container-form {
  @include containerNormal();
}

@media only screen and (max-width: 900px) {
  .new-booking-container-form {
    @include containerTablet();
  }
}

@media only screen and (max-width: 600px) {
  .new-booking-container-form {
    @include containerMobile();
  }
}
