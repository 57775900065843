.custom-field {
  .ui-textarea-field {
    height: auto;
    min-height: 40px;
    padding: 10px 0;

    textarea {
      resize: vertical;
    }
  }
}
