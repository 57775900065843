.new-booking-step-form-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-bottom: solid 1px #e5e5e5;
  padding: 0;
  height: 55px;
  align-items: center;

  &.closed {
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgb(124 133 152 / 15%);

    &.error {
      background-color: #dd5147;
    }
  }

  .new-booking-item-status-of-step-chip {
    margin-left: 8px;
    background-color: #1bace0;

    &.new-booking-step-success-completed {
      background-color: #499f68;
    }
  }

  .new-booking-step-cancelled {
    background-color: #e4746c;
  }
}
