@import 'stepFieldsHelpers';

.steps {
  @include stepNormal();
}

.steps {
  &.step-vertical {
    @include stepVertical();
  }
}

@media only screen and (max-width: 1000px) {
  .steps {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media only screen and (max-width: 600px) {
  .steps {
    @include stepMobile();

    &.step-vertical {
      @include stepMobile();
    }
  }

  .steps {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
