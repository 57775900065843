.table {
  min-width: 400px;
  font-family: Arial, serif;

  .table-header {
    .header-cell {
      color: #9ea5a9;
      font-size: 12px;
      padding: 5px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .table-body {
    .body-row {
      &:hover {
        background-color: #f0f8fb;
        cursor: pointer;

        .action-utilities {
          visibility: visible;
        }
      }

      .action-utilities {
        visibility: hidden;
      }

      .body-cell {
        color: #2a3236;
        text-align: left;
        padding-left: 5px;
        font-size: 13px;
      }
    }
  }
}
