@mixin containerNormal() {
  .cat-container {
    // Default
    &.custom-field {
      grid-column: span 2;
    }

    &.field-need_refrigerate {
      grid-column: span 4;
    }

    &.field-hazardous {
      grid-column: span 4;
    }

    &.field-empty_park {
      grid-column: span 4;
    }

    &.field-code {
      grid-column: span 2;
    }

    &.field-iso_type {
      grid-column: span 3;
    }

    &.field-tue_count {
      grid-column: span 2;
    }

    &.field-slot_date {
      grid-column: span 3;
    }

    &.field-slot_reference {
      grid-column: span 2;
    }

    &.field-empty_release_no {
      grid-column: span 2;
    }

    &.field-freight_direction {
      grid-column: span 2;
    }

    &.field-door_direction {
      grid-column: span 2;
    }

    &.field-seal_no {
      grid-column: span 2;
    }

    &.field-sealed_by {
      grid-column: span 2;
    }

    &.field-max_gross_weight {
      grid-column: span 2;
    }

    &.field-tear_weight {
      grid-column: span 2;
    }

    &.field-volume {
      grid-column: span 2;
    }

    &.field-description {
      grid-column: span 12;
    }
  }
}

@mixin containerTablet() {
  .cat-container {
    // Default
    &.custom-field {
      grid-column: span 4;
    }

    &.field-need_refrigerate {
      grid-column: span 4;
    }

    &.field-hazardous {
      grid-column: span 4;
    }

    &.field-empty_park {
      grid-column: span 4;
    }
  }
}

@mixin containerMobile() {
  .cat-container {
    // Default
    &.custom-field {
      grid-column: span 6;
    }

    &.field-slot_date {
      grid-column: span 12;
    }

    &.field-empty_park {
      grid-column: span 12;
    }
  }
}
