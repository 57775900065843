.booking-item-actions {
  // padding: 15px 0 0;
  // margin-right: 16px;

  .actions {
    display: flex;
    justify-content: flex-end;

    .new-booking-delete-item {
      margin-right: 10px;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }
  .actionInlineBlock {
    display: inline-block;
  }
  .clear-btn {
    color: rgba(206, 31, 51, 1);
    font-weight: bold;
    font-family: Arial, sans-serif;
    text-transform: none;
  }
  .duplicate-btn {
    color: #1bace0;
    font-weight: bold;
    font-family: Arial, sans-serif;
    text-transform: none;
  }
}

.new-booking-item-more-actions {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  margin: 8px;
  width: 130px;
  align-items: flex-start;

  button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-transform: none;
  }
}
