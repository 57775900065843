.new-booking-leg-section {
  padding: 0 0 16px !important;

  &.close {
    padding-bottom: 0 !important;

    &.error {
      background-color: #dd5147;
    }
  }

  .steps-collapse-wrapper {
    padding: 0 16px;

    @media only screen and (max-width: 600px) {
      padding: 0 8px;
    }

    .steps {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 8px;

      &.step-vertical {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
