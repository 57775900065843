:root {
  --primary-color: #1bace0;
  --primary-tint: #e8f7fc;
  --primary-dark: #0d536b;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
}

.react-datepicker {
  margin: 0px 0px 0px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: var(--primary-tint);
  color: var(--primary-color);
  font-weight: bold;
}
.react-datepicker__header {
  background-color: white;
  color: var(--primary-color);
  justify-content: space-between;
  padding-top: 0px;
}
.react-datepicker__current-month {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__navigation {
  margin: 0px;
}
.react-datepicker__day-names {
  background-color: var(--primary-tint);
  justify-content: space-between;
  height: 40px;
  padding-top: 4px;
}
.react-datepicker__day-name {
  width: 30px;
  margin-right: 0px;
  margin-left: 0px;
  font-size: 0.8em;
}
.react-datepicker__today-button {
  background-color: var(--primary-color);
  color: white;
  border-bottom: 3px solid var(--primary-color);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.react-datepicker__today-button:hover {
  background-color: var(--primary-dark);
}
.react-datepicker__day {
  margin: 0;
  width: 30px;
  height: 30px;
  font-size: 0.8em;
  font-family: 'Open sans', system-ui;
  border-radius: 0%;
}
.react-datepicker__day:hover {
  background-color: var(--primary-color);
  color: white;
  font-weight: bolder;
}
.react-datepicker__day--selected {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.react-datepicker__day--in-selecting-range {
  background-color: var(--primary-tint);
  color: var(--primary-color);
  border-radius: 0%;
}
.react-datepicker__day--selected-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.react-datepicker__day--in-range {
  border-radius: 0%;
  background-color: var(--primary-tint);
  color: var(--primary-color);
  font-weight: bold;
}
.react-datepicker__day--range-start {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.react-datepicker__day--range-end {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.react-datepicker__day--today {
  box-shadow: inset 0 0 2px var(--primary-color);
  border-radius: 4px;
}
