.cod-price-custom-field-adornment {
  &.disabled {
    .MuiTypography-root {
      color: rgba(117, 117, 117, 1);
    }
  }

  .MuiTypography-root {
    font-size: 13px;
    color: #000;
    font-weight: 500;
    margin-right: 5px;
  }
}
