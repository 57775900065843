.with-unit-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .MuiFormControl-root,
  .manage-MuiFormControl-root {
    .MuiInputBase-root,
    .manage-MuiInputBase-root {
      border-right: none;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }
  }
}
