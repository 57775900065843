.uppercase {
  text-transform: uppercase;
}

.font-thin {
  font-weight: 100;
}

.font-extralight {
  font-weight: 200;
}

.font-normal {
  font-weight: 400;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

.line-height-21 {
  line-height: 21px;
}

.font-13 {
  font-size: 13px;
}
