.new-booking-items-section {
  padding: 0 0 16px !important;

  &.close {
    padding-bottom: 0 !important;
  }

  .items-section-collapse-wrapper {
    padding: 0 16px;

    @media only screen and (max-width: 600px) {
      padding: 0 8px;
    }

    .add-new-item {
      color: #2f7ae2;
      display: flex;
      width: 100%;
      border-radius: 8px;
      background-color: rgba(47, 122, 226, 0.1);
      height: 56px;
      margin-top: 10px;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
      }
    }
  }
}
