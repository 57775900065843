.new-booking-booking-information-section {
  &.form-closed {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgb(124 133 152 / 15%);

    &.error {
      background-color: #dd5147;
    }
  }

  .booking-information-body {
    border: none;
    box-shadow: none;
  }
}
