@import 'itemFields';

.item-section {
  padding: 0 0 16px !important;

  &.close {
    padding-bottom: 0 !important;
  }

  .item-form-collapse-wrapper {
    padding: 0 16px;

    @media only screen and (max-width: 600px) {
      padding: 0 8px;
    }

    .item-form {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 8px;
    }
  }
}

.item-container-wrapper {
  padding-top: 8px;
  // Note: margin should NOT be used for each item of Virtuoso component due to this issue: 
  // https://virtuoso.dev/troubleshooting#list-does-not-scroll-to-the-bottom--items-jump-around
}
