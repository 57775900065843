.map-view {
  width: 100%;
  height: 100%;
}

$MAP_ICON_SIZE: 20px;
$HUB_SIZE: 26px;

.leg-marker {
  z-index: 5;
  min-width: $MAP_ICON_SIZE;
  height: $MAP_ICON_SIZE;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ffffff;
  position: relative;
  cursor: pointer;
}

.leg_icon_text {
  height: $MAP_ICON_SIZE;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: $MAP_ICON_SIZE - 8px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: $MAP_ICON_SIZE + 1px;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  justify-content: center;
}

.hub-marker {
  z-index: 100;
  border-radius: 50%;
  width: $HUB_SIZE;
  height: $HUB_SIZE;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.5);
  background-color: #d7680c;
  border: solid 1px #ffffff;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 25px;
  margin-left: -13px;
  margin-top: -13px;
  color: #ffffff;
}

.right-arrow-icon {
  width: 15px;
  height: 15px;
}

.info-marker-content {
  font-family: Arial;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.3px;
  color: #ffffff;
  margin-bottom: 5px;
  word-wrap: break-word;
  text-align: center;
}
.info-marker-content:hover {
  text-decoration: underline;
}

.info-address-marker-title {
  color: rgb(91, 137, 212);
  font-family: Arial;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  overflow: hidden;
  margin-bottom: 8px;
  text-align: center;
}

.marker-icon-container {
  position: absolute;
  border-radius: 11px;
  display: flex;
  flex: 1;
  width: 22px;
  height: 22px;
  border: 1px solid white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  object-fit: contain;
  cursor: pointer;
}

.marker-icon-container:hover,
.marker-icon-container.active {
  width: 24px;
  height: 24px;
  border-radius: 12px;
}
.marker-icon-container.active .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.marker-icon-container-position {
  position: absolute;
  display: flex;
  flex: 1;
  width: 22px;
  height: 22px;
  border: 1px solid white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  object-fit: contain;
  cursor: pointer;
}

.marker-icon-container-position:hover {
  width: 24px;
  height: 24px;
}

.marker-icon-container-position {
  position: absolute;
  display: flex;
  flex: 1;
  width: 22px;
  height: 22px;
  border: 1px solid white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  object-fit: contain;
  cursor: pointer;
}

.marker-icon-container-position:hover {
  width: 24px;
  height: 24px;
}

.info-container {
  padding-bottom: 0px;
  max-height: 96px;
  overflow-y: auto;
  min-width: 140px;
  max-width: 160px;
  ::-webkit-scrollbar:vertical {
    width: 11px;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.row-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-stop-text {
  font-family: Arial;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: #ffffff;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-self: center;
  align-content: center;
  align-items: center;
  flex: 1;
}

.tooltip .tooltiptext {
  padding: 12px;
  visibility: hidden;
  background-color: #2f3033;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext.active,
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #2f3033 transparent transparent transparent;
}

.hub-icon {
  width: 22px;
  height: 22px;
}

.hub-icon:hover {
  width: 24px;
  height: 24px;
}

.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  min-width: 200px;
}

.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: 5px;
  left: 0;
}

.popup-bubble {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  background-color: #2f3033;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  max-height: 60px;
  font-family: Arial;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.3px;
  color: #ffffff;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.popup-bubble-anchor::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2f3033;
}

.online-indicator {
  width: 7px;
  height: 7px;
  border-radius: 3.5px;
  margin-right: 4px;
}

.highlighted-marker {
  width: 26px;
  height: 26px;
  border-radius: 13px;
}
