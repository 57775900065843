.address-locator-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  iframe {
    width: calc(100% - 72px);
    height: calc(100vh - 68px);
    position: absolute;
  }
}

.embed-address-picker {
  &.fullscreen-map-view {
    z-index: 1;
    top: 5%;
    right: 5%;
    position: absolute;
    width: 90%;
    height: 90%;

    .form-container {
      position: absolute;
      top: 22px;
      left: 24px;
      z-index: 2;
      background: white;
      padding: 20px 30px;
      width: 294px;
      border-radius: 4px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

      .search-bar-container {
        .autocomplete-container {
          border: 1px solid #b7c0c5;
          padding: 8px 6px;
          border-top: 0;
          margin-top: -12px;
          border-radius: 0 0 4px 4px;

          .suggestion-item {
            cursor: pointer;
            padding: 5px 2px;
            font-size: 13px;

            &.active {
              background-color: #f4f4f4;
            }
          }
        }
      }

      .title {
        min-height: 17px;
        line-height: 17px;
        font-family: Arial, serif;
        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #2a3236;
        text-transform: uppercase;
        margin: 0;
      }

      .sub-title {
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        color: #4d5a61;
        margin: 4px 0 14px;
      }
    }
  }

  .form-group-picker {
    margin-bottom: 10px;

    label {
      height: 12px;
      font-family: Arial, serif;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #5e737d;
      margin-bottom: 5px;
      display: block;

      &.checkbox-label {
        display: flex;
        input {
          margin-left: 0;
        }
      }
    }

    .form-control {
      .field {
        width: 100%;
        padding: 7px 10px;
        border-radius: 3px;
        border: solid 1px #b7c0c5;
        background-color: #ffffff;
        font-family: Arial, serif;
        font-size: 12px;
        color: #38464c;

        &:focus {
          outline: none;
          outline-offset: 0;
        }
      }
    }
  }

  .marker {
    transform: translate(-50%, -100%);
  }

  .btn-container-picker {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn {
      min-width: 96px;
      height: 30px;
      border-radius: 4px;
      border: solid 1px #b7c0c5;
      background-color: #ffffff;
      font-size: 12px;
      font-weight: bold;
      color: #b7c0c5;
      text-transform: uppercase;
      margin: 0;
      cursor: pointer;
      width: initial;

      &.btn-primary {
        background-color: #2f7ae2;
        color: #ffffff;

        &:disabled {
          opacity: 0.4;
        }
      }
    }
  }

  .map-view {
    position: absolute;
    top: 145px;
    width: 75%;
    height: calc(100% - 145px);
  }

  .gm-style:first-of-type > div:nth-child(1) {
    cursor: url('./movepin-icon.svg') 15 15, crosshair !important;
  }
}
