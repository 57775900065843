@import './table.scss';
@import './button.scss';
@import './input.scss';
@import './page.scss';
@import './icon.scss';
@import './utils.scss';
@import './text.scss';

.square-20px {
  width: 20px !important;
  height: 20px !important;
}

.flex {
  display: flex;
}
.items-baseline {
  align-items: baseline;
}
.items-center {
  align-items: center;
}

.w-200 {
  width: 200px;
}
.w-250 {
  width: 250px;
}
.w-300 {
  width: 300px;
}
.w-400 {
  width: 400px;
}

.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.ml--10 {
  margin-left: -10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.opacity-50 {
  opacity: 0.5;
}
