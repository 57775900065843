@import 'itemFieldsHelpers';

.item-form {
  @include itemNormal();
}

@media only screen and (max-width: 900px) {
  .item-form {
    @include itemTablet();
  }
}

@media only screen and (max-width: 600px) {
  .item-form {
    @include itemMobile();
  }
}
