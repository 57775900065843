.custom-field {
  .ui-phone-number-field {
    input {
      padding-left: 0 !important;
    }

    .Mui-disabled {
      .MuiPhoneNumber-flagButton {
        pointer-events: none;
      }
    }
  }
}
