@mixin itemNormal() {
  .cat-item {
    // Default
    &.custom-field {
      grid-column: span 2;
    }

    &.field-external_customer_id1 {
      grid-column: span 3;
    }

    &.field-quantity {
      grid-column: span 2;
    }

    &.field-payload_type {
      grid-column: span 2;
    }

    &.field-weight {
      grid-column: span 2;
    }

    &.field-length {
      grid-column: span 2;
    }

    &.field-width {
      grid-column: span 2;
    }

    &.field-height {
      grid-column: span 2;
    }

    &.field-description {
      grid-column: span 12;
    }

    &.field-info {
      grid-column: span 12;
    }
  }
}

@mixin itemTablet() {
  .cat-item {
    // Default
    &.custom-field {
      grid-column: span 3;
    }

    &.field-external_customer_id1 {
      grid-column: span 4;
    }

    &.field-quantity {
      grid-column: span 2;
    }

    &.field-payload_type {
      grid-column: span 3;
    }

    &.field-weight {
      grid-column: span 3;
    }

    &.field-length {
      grid-column: span 3;
    }

    &.field-width {
      grid-column: span 3;
    }

    &.field-height {
      grid-column: span 3;
    }

    &.field-description {
      grid-column: span 12;
    }

    &.field-info {
      grid-column: span 12;
    }
  }
}

@mixin itemMobile() {
  .cat-item {
    // Default
    &.custom-field {
      grid-column: span 6;
    }

    &.field-external_customer_id1 {
      grid-column: span 6;
    }

    &.field-quantity {
      grid-column: span 6;
    }

    &.field-payload_type {
      grid-column: span 6;
    }

    &.field-weight {
      grid-column: span 6;
    }

    &.field-length {
      grid-column: span 6;
    }

    &.field-width {
      grid-column: span 6;
    }

    &.field-height {
      grid-column: span 6;
    }

    &.field-description {
      grid-column: span 12;
    }

    &.field-info {
      grid-column: span 12;
    }
  }
}
