.field-address {
  input {
    padding-bottom: 3px;
  }

  .custom-field-address-adornment {
    cursor: pointer;
  }
}

.custom-field-address-select-listbox {
  display: contents;

  @media only screen and (max-width: 600px) {
    li[role='option'] {
      padding: 0 8px;
      min-height: 32px;
    }
  }

  .MuiListSubheader-root {
    background-color: rgb(236, 236, 236);
    line-height: 32px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;

    @media only screen and (max-width: 600px) {
      padding-left: 8px;
    }
  }

  .custom-field-address-select-option-item {
    font-size: 13px !important;
  }
}
