@import 'stepFields';

.new-booking-step-form {
  padding: 0 0 16px !important;

  &.closed {
    padding: 0 !important;
    border: none;
  }

  .step-wrapper-fields {
    padding: 0 16px;
    position: relative;

    @media only screen and (max-width: 600px) {
      padding: 0 8px;
    }

    .custom-field:first-child {
      margin-top: 10px;
    }

    .MuiCollapse-wrapperInner {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      column-gap: 8px;
    }
  }

  .MuiSkeleton-root {
    grid-column: span 4;
  }
}
