.page-block {
  flex: 1;
  padding: 20px 30px;
  min-height: 528px;
  overflow: scroll;
  background-color: white;

  .page-block-body {
    margin-bottom: 40px;

    .sub-title {
      margin-top: 40px;
      text-transform: uppercase;
      color: #2a3236;
      font-family: 'Arial-BoldMT', serif;
      font-size: 13px;
    }
  }

  .page-block-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 21px;

    .title {
      display: flex;
      align-items: center;
      font-family: 'Arial-BoldMT', serif;
      text-transform: uppercase;
      font-size: 16px;
      color: #2a3236;
    }

    .title-right-button {
      button {
        margin-right: 0;
      }
    }
  }
}
