.booking-information-body {
  .leg-actions {
    display: flex;
    align-items: center;
    margin-right: 8px;

    .MuiIconButton-root {
      padding: 0;
      margin-right: 16px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .step-actions {
    margin-right: 8px;
  }
}

.new-booking-leg-more-actions {
  display: flex;
  flex-direction: column;
  padding: 8px 8px;
  margin: 8px;
  width: 150px;
  align-items: flex-start;

  button {
    text-transform: none;
  }
}


