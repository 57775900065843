.new-booking-item-header-section {
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: auto auto;
  min-height: 55px;

  &.form-closed {
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgb(124 133 152 / 15%);

    &.error {
      background-color: #dd5147;
    }
  }

  .new-booking-item-header-toggle-btn-wrapper {
    grid-column: 1;
    grid-row: 1 / 3;
  }

  .new-booking-item-header-main-content {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    justify-content: space-between;

    .new-booking-item-header-section-content-highlight-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-top: 12px;
      .new-booking-item-completed-group {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .new-booking-item-status,
      .booking-item-tracking-number,
      .booking-item-index {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
        text-transform: uppercase;

        &.new-booking-item-statistics {
          color: #1bace0;
        }

        &.new-booking-item-completed {
          color: #499f68;
        }

        &.new-booking-item-cancelled {
          color: #e4746c;
        }
      }
      .new-booking-item-quantity {
        margin-left: 10px;

        .equal {
          color: green;
        }
        .higher {
          color: #168ab3;
        }
        .lower {
          color: red;
        }
      }
    }
  }

  .booking-item-tracking-numbers {
    grid-column: 2;
    grid-row: 2;
    display: flex;
    margin: 8px 0;

    .booking-global-item-tracking-number,
    .booking-partner-order-number,
    .booking-partner-item-tracking-number {
      border-radius: 4px;
      font-size: 11px;
      color: white;
      padding: 0 8px;
      background-color: #1bace0;
      text-transform: uppercase;
      height: 24px;
      display: flex;
      align-items: center;
      margin-right: 8px;
      white-space: nowrap;
    }
  }
}
