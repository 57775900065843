.new-booking-order-form-section {
  &.form-closed {
    height: 55px;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgb(124 133 152 / 15%);

    &.error {
      background-color: #dd5147;
    }
  }

  .new-booking-order-form {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(12, 1fr);

    .cat-order {
      &.custom-field {
        grid-column: span 6;
      }

      &.field-external_id {
        grid-column: span 12;
      }

      @media only screen and (max-width: 600px) {
        &.custom-field {
          grid-column: span 12;
        }
      }
    }

    .MuiSkeleton-root {
      grid-column: span 6;

      &:first-child {
        grid-column: span 12;
      }

      @media only screen and (max-width: 600px) {
        grid-column: span 12;
      }
    }
  }
}
