.field-type {
  width: 125px;
  margin: 0;
  display: inline-block;

  & > div {
    margin: 0;
  }

  .form-field-type {
    margin: 0;

    div {
      font-weight: 600;
    }
  }

  label {
    display: none;
  }
}
